import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { Container, Panel, BackButton } from '../../../../../Components';
import { LawHeader } from '../LawHeader';
import { LawCompanyUnits } from './CompanyUnits/LawCompanyUnits.List';
import { ICustomerLaw } from './CustomerLaws.List';
import { UserContext } from '../../../../../Context/UserContext/UserContext';
import { useAptorApi } from '../../../../../Api';
import { ArrowUpward } from '@material-ui/icons'
import { useStyles } from "../../../AdministrationModule.styles";


export const EditCustomerLaw = () => {
  const match = useRouteMatch<{ lawid: string }>();
  const id = parseInt(match.params.lawid);
  const { formatMessage } = useIntl();
  const { api, abortController } = useAptorApi();
  const { getCompanyUnitName } = useContext(UserContext);
  const [name, setName] = useState<string>();
  const classes = useStyles()
  const history=useHistory()
  //Initialization
  useEffect(() => {
    const initializeName = async () => {
      const law = await api.getCustomerLaw<ICustomerLaw>(id);
      if (abortController.current.signal.aborted) {
        return;
      }
      setName(law.number + ' ' + law.name);
    };
    if (name === undefined) {
      initializeName();
    }
  }, [id, name, api, abortController]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container>
        {/* <BackButton link="/organization/law-portal/laws/" /> */}
        <BackButton link="" onclick={()=>history.goBack()} />
        {name && (
          <Grid container spacing={4} direction="column">
            <Grid item>
              <LawHeader name={name} />
            </Grid>
            <Grid item>
              <Panel
                titleKey="law-portal.manage.edit-law.connectedCompanyUnits"
                titleValues={{ alias: getCompanyUnitName(formatMessage, true) }}
              >
                <LawCompanyUnits lawId={id} />
              </Panel>
            </Grid>
          </Grid>
        )}
      </Container>
      {showScrollTop && (
        <Tooltip title={formatMessage({id:"bottom.to.top.btn.tooltip.text",defaultMessage:"Back to top"})}>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className={classes.topButton}
          >
            <ArrowUpward style={{ fontSize: 20 }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};
