import { makeStyles } from '@material-ui/core';

export const useStyles:any = makeStyles((theme:any) => ({
  listWrapper: {
    width: '100%',
    position: 'relative',
  },
  topButton: {
    borderRadius: ' 50%',
    position: 'fixed',
    bottom: '10px',
    transform: 'translateX(-44px)',
    justifyContent: 'center',
    alignItems: 'center',
    right: 0,
    display: 'flex',
    //height: '46px',
    opacity: .7,
    width: 50,
    height: 50,
    zIndex:1111
  },
    root: {
    minHeight: '100%',
  },
  autoHeight: {
    minHeight: 'auto',
  },
  panelDetails: {
    display: 'block',
  },
  text:{
    textTransform:"none"
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight:'25px'
  },
}));
