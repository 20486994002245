import React, { FC, useCallback, useContext } from 'react';
import clsx from 'clsx';
import {
  ListItemText,
  ListItem,
  List,
  Card,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { LawQuestion, CustomerQuestion } from '../Checklist.Question/Checklist.Question';
import { useIntl } from 'react-intl';
import { useStyles } from './Checklist.Group.styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UserContext } from '../../../../../../../../Context/UserContext/UserContext';

interface ILawQuestion {
  id: number;
  name: string;
  hidden: boolean;
}

interface ILawQuestionGroupProps {
  id: number;
  name: string;
  groupItems: ILawQuestion[];
  toggleHidden: (questionId: number, groupId: number | undefined, hidden: boolean) => void;
}

export const LawQuestionGroup: FC<ILawQuestionGroupProps> = ({ id, name, groupItems, toggleHidden }) => {
  const { formatMessage } = useIntl();
  const groupIsHidden = !groupItems.some((x) => !x.hidden);
  const buttonIcon = groupIsHidden ? <VisibilityOff /> : <Visibility />;
  const buttonTranslationKey = groupIsHidden
    ? 'law-portal.compliance.question.show-button'
    : 'law-portal.compliance.question.hide-button';

  const onToggleHidden = useCallback((questionId: number, _, hidden: boolean) => toggleHidden(questionId, id, hidden), [
    id,
    toggleHidden,
  ]);

  const onToggleGroupHidden = () => {
    const hidden = !groupIsHidden;
    groupItems.forEach((question) => {
      if (question.hidden !== hidden) {
        onToggleHidden(question.id, id, hidden);
      }
    });
  };

  const classes = useStyles();
  return (
    <Card variant="outlined" component="li">
      <List
        className={classes.list}
        aria-labelledby={name}
        subheader={
          <ListItem className={clsx(classes.header, { [classes.hidden]: groupIsHidden })}>
            <ListItemText disableTypography>
              <Typography component="h3" variant="h4" id={name}>
                {name}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Tooltip title={formatMessage({ id: buttonTranslationKey })}>
                <IconButton onClick={onToggleGroupHidden} aria-label={formatMessage({ id: buttonTranslationKey })}>
                  {buttonIcon}
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        }
      >
        {groupIsHidden === false &&
          groupItems.map((gi, index) => <LawQuestion key={index} {...gi} toggleHidden={onToggleHidden} />)}
      </List>
    </Card>
  );
};

interface ICustomerQuestionsProps {
  questions: Array<{ name: string }>;
  remove: (index: number) => void;
}

export const CustomerQuestions: FC<ICustomerQuestionsProps> = ({ questions, remove }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { customerName } = useContext(UserContext);

  return (
    <Card className={classes.card} variant="outlined">
      <List
        aria-labelledby="customerQuestions"
        subheader={
          <ListItem className={classes.header}>
            <ListItemText>
              <Typography component="h3" variant="h4" id="customerQuestions">
                {formatMessage(
                  { id: 'law-portal.manage.checklist.customer-questions.label' },
                  { customerName: customerName },
                )}
              </Typography>
            </ListItemText>
          </ListItem>
        }
      >
        {questions.map((gi, index) => (
          <CustomerQuestion key={index} index={index} {...gi} remove={remove} />
        ))}
      </List>
    </Card>
  );
};
