import React, { FC, useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip,
  Input,
  TextField,
  Button,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Delete, Edit, DragIndicator } from '@material-ui/icons';
import { useConfirmation } from '../../../../../Context/ConfirmationContext/ConfirmationContext';
import { useStyles } from './Checklist.Item.styles';
import { FormattedMessage, useIntl } from 'react-intl';

export interface IProps {
  label: string;
  disableUp?: boolean;
  disableDown?: boolean;
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  skipRemoveConfirmation?: boolean;
  header?: boolean;
  itemindex: any;
  editItem: (val: any) => void;
}

export const ChecklistItem: FC<IProps> = ({
  label,
  disableUp,
  disableDown, 
  moveUp,
  moveDown,
  remove,
  skipRemoveConfirmation,
  header,
  itemindex,
  editItem,
}) => {
  const { deleteConfirmation } = useConfirmation();
  const asyncRemove = () =>
    new Promise<void>((resolve) => {
      remove();
      resolve();
    });
  const onRemove = () => (skipRemoveConfirmation ? remove() : deleteConfirmation(asyncRemove, { name: label }));
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [editValue, setEditValue] = useState('');
  const [edit, setEdit] = useState(false);

  const secondaryAction = (
    <ListItemSecondaryAction>
      {edit ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            color="secondary"
            onClick={() => {
              setEdit(false);
              editItem(editValue);
            }}
          >
            <FormattedMessage id="form.save" defaultMessage="Save" />
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setEdit(false);
              setEditValue('');
            }}
          >
            <FormattedMessage id="form.cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      ) : (
        <div style={{display:'flex',alignItems:'center'}}>
          <Tooltip title={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}>
            <IconButton
              onClick={() => {
                setEdit(true);
                setEditValue(label);
              }}
              aria-label={formatMessage({ id: 'utils.icon.edit', defaultMessage: 'Edit' })}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}>
            <IconButton
              onClick={onRemove}
              aria-label={formatMessage({ id: 'utils.icon.delete', defaultMessage: 'Delete' })}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'admin.law-portal.law.checklist.list.up', defaultMessage: 'Move up' })}>
            <IconButton
              disabled={disableUp}
              onClick={moveUp}
              aria-label={formatMessage({ id: 'admin.law-portal.law.checklist.list.up', defaultMessage: 'Move up' })}
            >
              <ArrowUpward />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={formatMessage({ id: 'admin.law-portal.law.checklist.list.down', defaultMessage: 'Move down' })}
          >
            <IconButton
              disabled={disableDown}
              onClick={moveDown}
              aria-label={formatMessage({
                id: 'admin.law-portal.law.checklist.list.down',
                defaultMessage: 'Move down',
              })}
            >
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={formatMessage({ id: 'admin.law-portal.law.checklist.list.drag', defaultMessage: 'Drag' })}
          >

              <DragIndicator />
          </Tooltip>
        </div>
      )}
    </ListItemSecondaryAction>
  );

  return (
    <>
      {header ? (
        <ListItem className={`${classes.root} ${classes.header}`}>
          <ListItemText disableTypography>
            <Typography component="h3" variant="h4" id={label}>
              {itemindex}{' '}
              {edit ? <TextField value={editValue} onChange={(e) => setEditValue(e.target.value)} /> : label}
            </Typography>
          </ListItemText>
          {secondaryAction}
        </ListItem>
      ) : (
        <ListItem  className={classes.root} classes={{ container: classes.container }}>
          <ListItemText>
            {itemindex} {edit ? <TextField value={editValue} onChange={(e) => setEditValue(e.target.value)} /> : label}
          </ListItemText>
          {secondaryAction}
        </ListItem>
      )}
    </>
  );
};
