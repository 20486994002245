import React, { useContext, useEffect } from 'react'
import { ReportFilter } from "./ReportFIlter"
import { useFilter } from "../../../../../Components/ListFilter";
import { Link, useLocation } from "react-router-dom";
// import { Input, Theme, createStyles } from "@material-ui/core";
import { MTable, Panel } from "../../../../../Components";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useIntl } from "react-intl";
import { useAptorApi } from "../../../../../Api";
import { AccessLevel, LawPortalFeature, UserContext } from "../../../../../Context/UserContext/UserContext";
import moment from "moment";

type CompliancecReport = {
  id: number;
  requirementId: number;
  requirementName: string;
  effectiveFrom: Date;
  customerCompanyUnitId: number;
  customerCompanyUnit: string;
  userId: number;
  userName: string;
  controlDate: Date;
  unplanned: boolean;
};

export const ReportsKEK = () => {
  const [filters, setFilter] = useFilter();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { complianceUserFor, hasAccessToFeature, getCompanyUnitName } = useContext(UserContext);
  const { api } = useAptorApi();
  const { formatMessage } = useIntl();
  const tableRef = React.createRef();
  const canDownloadAllReports = hasAccessToFeature(LawPortalFeature.Manage, AccessLevel.Manage);
  useEffect(() => {
    const queryParamsMap = {
      status: 'status',
      companyUnit: 'companyUnit',
      complianceUsers: 'complianceUsers',
      processes: 'processes',
      aspects: 'aspects',
      category: 'category',
      area: 'category',
    };
    Object.entries(queryParamsMap).forEach(([queryKey, stateKey]) => {
      const queryValues = queryParams.getAll(queryKey);
      if (queryValues.length) {
        setFilter(stateKey, queryValues.join(',').split(',').map(num => num.toString()));
      }
    });
  }, []);


  const canDownloadReport = (row: CompliancecReport) => {
    return (
      canDownloadAllReports ||
      complianceUserFor.laws.some(
        (x) => x.customerCompanyUnitId === row.customerCompanyUnitId && x.id === row.requirementId,
      )
    );
  };

  const renderDownloadAction = (row: CompliancecReport) => {
    return {
      tooltip: formatMessage({ id: 'law-portal.statistics.table.download-compliance' }),
      icon: 'picture_as_pdf',
      onClick: () => {
        api.downloadRequirementComplianceReport(row.requirementId, row.id);
      },
      disabled: !canDownloadReport(row),
    };
  };
  const renderDate = (date?: Date) => {
    return date ? moment(date).format('ll') : undefined;
  };
  return (
    <div>
      <ReportFilter filters={filters} setFilter={setFilter} />
      <hr style={{marginTop: '25px',marginBottom:"25px"}} />
      <div>
        <h2>{formatMessage({id:"law-portal.compliance.repporter.kek.heading.plan"})}</h2>

        <div>
          <Panel titleKey="24-01-01 --  2024-04-01 BOLAGETS ARLIGA LEK" actionIcon={<ExpandMoreIcon />} accordion>
            <h3 style={{ margin: 0 }}>{`${formatMessage({ id: 'utils.filters.status' })}: ${10} ${formatMessage({ id: "text.of" })} ${15} ${formatMessage({ id: "status.text" })} `}</h3>
            <div style={{paddingTop:"10px",paddingLeft:"30px",paddingRight:"30px"}}>
              <MTable
                tableRef={tableRef}
                columns={[
                  {
                    title: formatMessage({ id: 'law-portal.requirment.krev.col' }),
                    field: 'lawName',
                    render: (row:any) => <Link to={`/law-portal/our-laws/${row.lawId}`}>{row.lawName}</Link>,
                  },
                  {
                    title: getCompanyUnitName(formatMessage),
                    field: 'customerCompanyUnit',
                  },
                  {
                    title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
                    field: 'userName',
                  },
                  {
                    title: formatMessage({ id: 'law-portal.statistics.table.control-date' }),
                    field: 'controlDate',
                    render: (row) => renderDate(row.controlDate),
                  }
                ]}
                hideToolbar
                data={[]}
                actionLabel={''}
                actions={[renderDownloadAction]}
                components={{}}
              />
            </div>
          </Panel>
        </div>
      </div>
      <hr style={{ marginTop: '25px',marginBottom:"25px"}} />
      <div>
        <h2>{formatMessage({id:"law-portal.compliance.repporter.kek .heading.direct"})}</h2>

        <div>
          <Panel titleKey="2023-09-22 - KEMIKALIELAGSTIFTNING" actionIcon={<ExpandMoreIcon />} accordion>
          <h3 style={{ margin: 0 }}>{`${formatMessage({ id: 'utils.filters.status' })}: ${10} ${formatMessage({ id: "text.of" })} ${15} ${formatMessage({ id: "status.text" })} `}</h3>
            <div style={{paddingTop:"10px",paddingLeft:"30px",paddingRight:"30px"}}>
              <MTable
                tableRef={tableRef}
                columns={[
                  {
                    title: formatMessage({ id: 'law-portal.requirment.krev.col' }),
                    field: 'lawName',
                    render: (row:any) => <Link to={`/law-portal/our-laws/${row.lawId}`}>{row.lawName}</Link>,
                  },
                  {
                    title: getCompanyUnitName(formatMessage),
                    field: 'customerCompanyUnit',
                  },
                  {
                    title: formatMessage({ id: 'law-portal.compliance.report.table.col' }),
                    field: 'userName',
                  },
                  {
                    title: formatMessage({ id: 'law-portal.statistics.table.control-date' }),
                    field: 'controlDate',
                    render: (row) => renderDate(row.controlDate),
                  }
                ]}
                hideToolbar
                data={[]}
                actionLabel={''}
                actions={[renderDownloadAction]}
                components={{}}
              />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  )
}
