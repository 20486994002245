import React, { FC, useState } from 'react';
import { List } from '@material-ui/core';
import { ChecklistItem } from '../Checklist.Item/Checklist.Item';
import { ChecklistGroupItem } from '../Checklist.Group/Checklist.Group';
import { useStyles } from './Checklist.List.styles';

interface IQuestion {
  id?: number;
  name: string;
}

export interface IListItem extends IQuestion {
  type: 'group' | 'question';
  groupItems: IQuestion[];
}

interface IProps {
  items: IListItem[];
  updateItems: (items: IListItem[]) => void;
}

export const Checklist: FC<IProps> = ({ items, updateItems }) => {
  const classes = useStyles();
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [draggedChildIndex, setDraggedChildIndex] = useState<number | null>(null);

  const handleDragStart = (index: number, isChild: boolean, childIndex?: number) => {
    setDraggedIndex(index);
    if (isChild && childIndex !== undefined) {
      setDraggedChildIndex(childIndex);
    }
  };

  const handleDrop = (index: number, isChild: boolean, childIndex?: number) => {
    if (draggedIndex === null) return;

    if (isChild && draggedChildIndex !== null && childIndex !== undefined) {
      let newItems = [...items];
      const draggedItem = newItems[draggedIndex].groupItems[draggedChildIndex];
      newItems[draggedIndex].groupItems.splice(draggedChildIndex, 1);
      newItems[draggedIndex].groupItems.splice(childIndex, 0, draggedItem);
      updateItems(newItems);
      setDraggedIndex(null);
      setDraggedChildIndex(null);
      return;
    }

    const newItems = [...items];
    const [draggedItem] = newItems.splice(draggedIndex, 1);
    newItems.splice(index, 0, draggedItem);
    updateItems(newItems);
    setDraggedIndex(null);
  };

  const swapItems = (indexA: number, indexB: number) => {
    let newOrder = [...items];
    const a = newOrder[indexA];
    const b = newOrder[indexB];
    if (a.type === b.type || a.type === 'group') {
      newOrder[indexA] = b;
      newOrder[indexB] = a;
    } else if (a.type === 'question' && b.type === 'group') {
      const question = { id: a.id, name: a.name };
      newOrder[indexB].groupItems = indexA > indexB ? [...b.groupItems, question] : [question, ...b.groupItems];
      newOrder = newOrder.filter((i) => i !== a);
    }
    updateItems(newOrder);
  };

  const removeQuestion = (index: number) => updateItems(items.filter((_, i) => i !== index));

  const removeGroup = (index: number) => {
    const newItems = [...items];
    const group = newItems[index];
    newItems.splice(index, 1);
    if (group.groupItems.length > 0) {
      const questions = group.groupItems.map(
        (question) => ({ ...question, ...{ type: 'question', groupItems: [] } } as IListItem),
      );
      newItems.splice(index, 0, ...questions);
    }
    updateItems(newItems);
  };

  const onGroupItemsChange = (groupIndex: number, groupItems: IQuestion[]) => {
    let newItems = [...items];
    newItems[groupIndex].groupItems = groupItems;
    updateItems(newItems);
  };

  const onGroupMoveTopItemOut = (groupIndex: number) => {
    let newItems = [...items];
    const question = newItems[groupIndex].groupItems[0];
    newItems[groupIndex].groupItems.splice(0, 1);
    newItems.splice(groupIndex, 0, { ...question, ...{ type: 'question', groupItems: [] } });
    updateItems(newItems);
  };

  const onGroupMoveBottomItemOut = (groupIndex: number) => {
    let newItems = [...items];
    const bottomIndex = newItems[groupIndex].groupItems.length - 1;
    const question = newItems[groupIndex].groupItems[bottomIndex];
    newItems[groupIndex].groupItems.splice(bottomIndex, 1);
    newItems.splice(groupIndex + 1, 0, { ...question, ...{ type: 'question', groupItems: [] } });
    updateItems(newItems);
  };

  const editQuestionItem = (value: any, index: any) => {
    let newItems = [...items];
    newItems[index].name = value;
    updateItems(newItems);
  };

  const editGroupItem = (value: any, index: any) => {
    let newItems = [...items];
    newItems[index].name = value;
    updateItems(newItems);
  };

  const editGroupChildrenItem = (value: any, index: any, childindex: any) => {
    let newItems = [...items];
    newItems[index].groupItems[childindex].name = value;
    updateItems(newItems);
  };

  return (
    <List className={classes.root}>
      {items.map((item, index) => (
        <div
          key={index}
          draggable
          onDragStart={() => handleDragStart(index, false)}
          onDragOver={(e) => e.preventDefault()}
          onDrop={() => handleDrop(index, false)}
        >
          {item.type.toLowerCase() === 'question' && (
            <ChecklistItem
              label={item.name}
              itemindex={index + 1}
              moveUp={() => swapItems(index, index - 1)}
              moveDown={() => swapItems(index, index + 1)}
              disableUp={index === 0}
              disableDown={index === items.length - 1}
              remove={() => Promise.resolve(removeQuestion(index))}
              editItem={(val: any) => editQuestionItem(val, index)}
            />
          )}
          {item.type === 'group' && (
            <ChecklistGroupItem
              label={item.name}
              items={item.groupItems}
              itemindex={index + 1}
              moveUp={() => swapItems(index, index - 1)}
              moveDown={() => swapItems(index, index + 1)}
              disableUp={index === 0}
              disableDown={index === items.length - 1}
              onItemsChange={(items) => onGroupItemsChange(index, items)}
              moveTopItemOut={() => onGroupMoveTopItemOut(index)}
              moveBottomItemOut={() => onGroupMoveBottomItemOut(index)}
              remove={() => removeGroup(index)}
              editGroupChildrenItem={(value: any, childindex: any) => editGroupChildrenItem(value, index, childindex)}
              editGroupItem={(val: any) => editGroupItem(val, index)}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
            />
          )}
        </div>
      ))}
    </List>
  );
};
